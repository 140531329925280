import React, { Fragment, useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { archiveProject } from "../../lib/firestore";

const ArchiveProject = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleArchive = () => {
    setOpen(false);
    archiveProject(authCtx.organisation, props.projectName);
  };
  const authCtx = useContext(AuthContext);
  return (
    <Fragment>
      <IconButton aria-label="delete" onClick={handleClickOpen} color="primary">
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Removing project: ${props.projectName}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this project? All validations and
            feedback data may be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleArchive} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ArchiveProject;
