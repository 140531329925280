import React, { useState, useEffect, useContext } from "react";
import LayoutAppBar from "../components/layout/LayoutAppBar.js";
import ProjectCard from "../components/projects/ProjectCard.js";
import TrubricRunTable from "../components/trubrics/TrubricRunTable.js";
import TrubricValidations from "../components/trubrics/TrubricValidations.js";
import FeedbackIssues from "../components/feedback/FeedbackIssues.js";
import IssueDetail from "../components/feedback/IssueDetail.js";
import { Routes, useParams, Navigate, Route, Link } from "react-router-dom";
import { Box, CircularProgress, Grid, Tabs, Tab } from "@mui/material";
import { db } from "../lib/firestore";
import AuthContext from "../store/auth-context.js";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";

const Project = (props) => {
  const routeParams = useParams();
  const [trubrics, setTrubrics] = useState([]);
  const [issues, setIssues] = useState([]);
  const authCtx = useContext(AuthContext);
  const organisation = authCtx.organisation;
  const routeRunId = routeParams["*"].split("/")[1];
  useEffect(() => {
    // realtime update feedback issues
    const issuesCollectionRef = collection(
      db,
      "organisations",
      organisation,
      "projects",
      routeParams.projectId,
      "feedback"
    );
    const issuesQuery = query(issuesCollectionRef, orderBy("timestamp"));
    onSnapshot(issuesQuery, (snapshot) => {
      console.log("Fetched feedback issues.");
      setIssues(snapshot.docs.map((doc) => doc.data()));
    });

    // realtime update trubrics
    const trubricsCollectionRef = collection(
      db,
      "organisations",
      organisation,
      "projects",
      routeParams.projectId,
      "trubrics"
    );
    const trubricsQuery = query(trubricsCollectionRef, orderBy("timestamp"));
    onSnapshot(trubricsQuery, (snapshot) => {
      console.log("Fetched trubrics.");
      setTrubrics(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  if (props.projects && props.projects.length > 0) {
    const project = props.projects.find(
      (p) => p.project_name === routeParams.projectId
    );
    return (
      <LayoutAppBar projects={props.projects}>
        <Grid container justifyContent="space-between">
          <Grid item xs="auto">
            <ProjectCard
              key={project.project_name}
              projectName={project.project_name}
              createdBy={project.createdBy}
              createdOn={project.createdOn}
            />
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ borderColor: "divider", mt: 8.5 }}>
              <Tabs
                value={routeParams["*"]}
                aria-label="feedback and validations tabs"
                variant="fullWidth"
              >
                <Tab
                  label="Validations"
                  value={
                    routeRunId ? "validations/" + routeRunId : "validations"
                  }
                  component={Link}
                  to="validations"
                  sx={{ fontSize: 20 }}
                />
                <Tab
                  label="Feedback"
                  value={routeRunId ? "feedback/" + routeRunId : "feedback"}
                  component={Link}
                  to="feedback"
                  sx={{ fontSize: 20 }}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Routes>
          <Route path="*" element={<Navigate replace to="validations" />} />
          <Route
            path="validations"
            element={<TrubricRunTable trubrics={trubrics} />}
          />
          <Route
            path="validations/:runId"
            element={<TrubricValidations trubrics={trubrics} />}
          />
          <Route path="feedback" element={<FeedbackIssues issues={issues} />} />
          <Route
            path="feedback/:issueId"
            element={
              <IssueDetail
                issues={issues}
                trubricRunIds={trubrics.map((t) => t.timestamp)}
              />
            }
          />
        </Routes>
      </LayoutAppBar>
    );
  } else {
    return (
      <LayoutAppBar projects={props.projects}>
        {<CircularProgress />}
      </LayoutAppBar>
    );
  }
};

export default Project;
