import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import IssueDiscussion from "./IssueDiscussion";
import IssueMetadata from "./IssueMetadata";

const IssueDetail = (props) => {
  const routeParams = useParams();

  const issue = props.issues[routeParams.issueId - 1];
  return (
    <Grid container mt={2}>
      <Grid item xs={9}>
        <IssueDiscussion issue={issue} trubricRunIds={props.trubricRunIds} />
      </Grid>
      <Grid item xs={3} sx={{ px: 2 }}>
        <IssueMetadata issue={issue} />
      </Grid>
    </Grid>
  );
};

export default IssueDetail;
