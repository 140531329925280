import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import TrubricValidationsMetadata from "./TrubricValidationsMetadata";
import TrubricValidationsTable from "./TrubricValidationsTable";
import { timestampToDate } from "../../lib/utils.js";

const TrubricValidations = (props) => {
  const routeParams = useParams();
  const trubricRun = props.trubrics?.find(
    (p) => p.timestamp.toString() === routeParams.runId
  );

  return (
    <Grid container mt={2}>
      <Grid item xs={9}>
        <Typography variant="h5" color="secondary.main">
          {trubricRun.name}
        </Typography>
        <Typography color="grey.500">
          {`Trubric run on ${timestampToDate(trubricRun.timestamp)} by ${
            trubricRun.run_by.displayName
          }`}
        </Typography>
        <br />
        <br />
        <TrubricValidationsTable trubricRun={trubricRun} />
      </Grid>
      <Grid item xs={3} sx={{ px: 2 }}>
        <TrubricValidationsMetadata trubricRun={trubricRun} />
      </Grid>
    </Grid>
  );
};

export default TrubricValidations;
