import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Chip, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
}));

const columns = [
  {
    field: "vType",
    headerName: "Validation Type",
    flex: 1,
    renderCell: (params) => (
      <HtmlTooltip
        title={
          <ReactMarkdown components={Typography}>
            {params.row.explanation}
          </ReactMarkdown>
        }
      >
        <div>{params.value}</div>
      </HtmlTooltip>
    ),
  },
  {
    field: "vParams",
    headerName: "Validation Parameters",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      return params.value;
    },
  },
  {
    field: "severity",
    headerName: "Severity",
    width: 120,
    renderCell: (params) => {
      return (
        <Chip
          label={params.value}
          size="small"
          variant="outlined"
          color={
            params.value === "error" || params.value === "warning"
              ? params.value
              : "info"
          }
        />
      );
    },
  },
  {
    field: "outcome",
    headerName: "Outcome",
    editable: true,
    renderCell: (params) => {
      return (
        <HtmlTooltip
          title={
            <div>
              <pre>
                {params.row.results
                  ? params.row.results
                  : `No results for this
              validation.`}
              </pre>
            </div>
          }
        >
          <Typography
            color={params.value === "pass" ? "success.main" : "error.main"}
          >
            {params.value.toUpperCase()}
          </Typography>
        </HtmlTooltip>
      );
    },
  },
];

export default function TrubricValidationsTable(props) {
  const [pageSize, setPageSize] = useState(5);

  const rows = props.trubricRun.validations?.map((validation) => {
    return {
      id: Math.random(),
      vType: validation.validation_type,
      vParams:
        validation.validation_kwargs.kwargs.length !== 0
          ? JSON.stringify(validation.validation_kwargs.kwargs, null, 2)
          : null,
      severity: validation.severity,
      outcome: validation.outcome,
      explanation: validation.explanation,
      results: JSON.stringify(validation.result, null, 2),
    };
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection
      disableSelectionOnClick
      autoHeight
      rowHeight={37}
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  );
}
