import React, { Fragment } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import JsonMetadataPopout from "../feedback/JsonMetadataPopout";

function TrubricValidationsMetadata(props) {
  const routeParams = useParams();
  return (
    <Fragment>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={0}>
          <Typography variant="h5" color="primary.main">
            Passed:
          </Typography>
          <Typography
            variant="h5"
            color="primary.main"
          >{`${props.trubricRun.total_passed_percent}%`}</Typography>
        </Stack>
        <JsonMetadataPopout IssueMetadata={props.trubricRun.metadata} />
      </Stack>
      <Box mt={3.5}>
        <Divider />
        <Typography color="secondary.main">Run ID</Typography>
        <br />
        <Typography>{routeParams.runId}</Typography>
        <Divider />
        <Typography color="secondary.main">Git Commit</Typography>
        <br />
        <Typography style={{ overflowWrap: "break-word" }}>
          {props.trubricRun.git_commit}
        </Typography>
        <Divider />
        <Typography color="secondary.main">Model</Typography>
        <br />
        <Typography>{`${props.trubricRun.model_name} - ${props.trubricRun.model_version}`}</Typography>
        <Divider />
        <Typography color="secondary.main">Data</Typography>
        <br />
        <Typography>{`${props.trubricRun.data_context_name} - ${props.trubricRun.data_context_version}`}</Typography>
      </Box>
    </Fragment>
  );
}

export default TrubricValidationsMetadata;
