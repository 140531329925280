import React from "react";
import LayoutAppBar from "../components/layout/LayoutAppBar";
import NewProjectCard from "../components/projects/NewProjectCard";
import ProjectCard from "../components/projects/ProjectCard";
import { CircularProgress, Grid, Typography } from "@mui/material";

export const AllProjects = (props) => {
  const projectCards = (projects, loading) => {
    if (loading) {
      return (
        <Grid item>
          <CircularProgress />
        </Grid>
      );
    } else {
      if (projects.length > 0) {
        return projects.map((project, index) => (
          <Grid item key={index}>
            <ProjectCard
              projectName={project.project_name}
              createdBy={project.createdBy}
              createdOn={project.createdOn}
              link={true}
            />
          </Grid>
        ));
      } else {
        return <Typography>No projects found...</Typography>;
      }
    }
  };

  return (
    <LayoutAppBar projects={props.projects}>
      <NewProjectCard projects={props.projects}/>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        spacing={2}
        sx={{ mt: 1 }}
      >
        {projectCards(props.projects, props.loading)}
      </Grid>
    </LayoutAppBar>
  );
};

export default AllProjects;
