import React, { useState } from "react";
import { getOrganisationFromEmail } from "../lib/firestore";

const AuthContext = React.createContext({
  currentUser: null,
  isLoggedIn: false,
  login: (currentUser) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("currentUser")
  );
  const [organisation, setOrganisation] = useState(localStorage.getItem("org"));

  const loginHandler = async (authCurrentUser) => {
    setCurrentUser(JSON.stringify(authCurrentUser));
    const org = await getOrganisationFromEmail(authCurrentUser.email);
    localStorage.setItem("org", org);
    setOrganisation(org);
    localStorage.setItem("currentUser", JSON.stringify(authCurrentUser));
  };

  const logoutHandler = () => {
    setCurrentUser(null);
    setOrganisation(null);
    localStorage.removeItem("org");
    localStorage.removeItem("currentUser");
  };
  const userIsLoggedIn = !!currentUser;

  const contextValue = {
    currentUser: currentUser ? JSON.parse(currentUser) : null,
    organisation: organisation,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };
  // console.log(contextValue); // contains token, for dev only
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
