import React, { useContext, useState, useEffect } from "react";
import "./App.css";
import { Route, Navigate, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn.js";
import AllProjects from "./pages/AllProjects";
import Project from "./pages/Project.js";
import { db } from "./lib/firestore";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";

import { createTheme, ThemeProvider } from "@mui/material";
import AuthContext from "./store/auth-context";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e84e0f",
    },
    secondary: {
      main: "#4a88a0",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const App = () => {
  const authCtx = useContext(AuthContext);
  const [projects, setProjects] = useState([]);
  const [waitingProjectLoad, setWaitingProjectLoad] = useState(true);

  useEffect(() => {
    if (authCtx.organisation) {
      const projectsCollectionRef = collection(
        db,
        "organisations",
        authCtx.organisation,
        "projects"
      );
      const projectsQuery = query(projectsCollectionRef, orderBy("createdOn"));
      onSnapshot(projectsQuery, (snapshot) => {
        console.log("Fetched projects.");
        setProjects(
          snapshot.docs
            .filter((doc) => !doc.data().archived)
            .map((doc) => ({ ...doc.data(), project_name: doc.id }))
        );
      });
      setWaitingProjectLoad(false);
    }
  }, [authCtx.organisation]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/signin"
          element={
            authCtx.isLoggedIn ? (
              <Navigate replace to="/projects" />
            ) : (
              <SignIn />
            )
          }
        />
        <Route
          path="/projects"
          element={
            authCtx.isLoggedIn ? (
              <AllProjects projects={projects} loading={waitingProjectLoad} />
            ) : (
              <Navigate replace to="/signin" />
            )
          }
        />
        <Route
          path="/projects/:projectId/*"
          element={<Project projects={projects} />}
        />
        <Route
          path="*"
          element={
            authCtx.isLoggedIn ? (
              <Navigate replace to="/projects" />
            ) : (
              <Navigate replace to="/signin" />
            )
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
