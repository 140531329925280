import React from "react";
import { Divider, Stack, Typography, Box } from "@mui/material";
import IssueTags from "./IssueTags";
import JsonMetadataPopout from "./JsonMetadataPopout";

function TrubricValidationsMetadata(props) {
  return (
    <Box mt={12}>
      <Stack direction="column" spacing={1}>
        <JsonMetadataPopout IssueMetadata={props.issue.metadata} />
        <IssueTags issue={props.issue} />
      </Stack>
      <br />
      <Divider />
      <Typography color="secondary.main">Model</Typography>
      <br />
      <Typography>{`${props.issue.model_name} - ${props.issue.model_version}`}</Typography>
      <Divider />
      <Typography color="secondary.main">Data</Typography>
      <br />
      <Typography>{`${props.issue.data_context_name} - ${props.issue.data_context_version}`}</Typography>
      <Divider />
      <Typography color="secondary.main">Contributors</Typography>
      <br />
      {props.issue.collaborators.map((Contributors, index) => (
        <Typography key={index}>{Contributors}</Typography>
      ))}
    </Box>
  );
}

export default TrubricValidationsMetadata;
