import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { addIssueTags, db } from "../../lib/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import AuthContext from "../../store/auth-context";
import { useParams } from "react-router-dom";

export default function Tags(props) {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const authCtx = useContext(AuthContext);
  const organisation = authCtx.organisation;
  const routeParams = useParams();

  useEffect(() => {
    const tagsDocRef = doc(
      db,
      "organisations",
      organisation,
      "projects",
      routeParams.projectId,
      "feedback",
      props.issue.timestamp.toString()
    );
    onSnapshot(tagsDocRef, (snapshot) => {
      console.log("Fetched tags.");
      setTags(snapshot.data().tags ? snapshot.data().tags : []);
    });
  }, []);

  const addTagHandler = (tags) => {
    addIssueTags(
      organisation,
      routeParams.projectId,
      props.issue.timestamp.toString(),
      tags
    );
  };
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={["performance", "outlier", "to do"]}
      freeSolo
      value={tags}
      onChange={(_, value) => {
        addTagHandler(value);
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Add a tag"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
}
