export const timestampToDate = (rawDate) => {
  // timestamp with seconds to a date
  const date = new Date(rawDate * 1000);
  return date.toLocaleString();
};

export const timestampMsToDate = (rawDate) => {
  // timestamp with milliseconds to a date
  const date = new Date(rawDate);
  return date.toLocaleString();
};
