import { Box } from "@mui/material";
import NavAppBarDrawer from "./NavAppBarDrawer";

const LayoutAppBar = (props) => {
  return (
    <Box sx={{ width: "90%", mx: "auto", mt: 15 }}>
      <NavAppBarDrawer projects={props.projects} />
      {props.children}
    </Box>
  );
};

export default LayoutAppBar;
