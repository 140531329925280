import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AuthContext from "../../store/auth-context";
import { addProject } from "../../lib/firestore";

const NewProjectCard = (props) => {
  const [projectInput, setProjectInput] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  const currentProjectNames = () => {
    return props.projects?.map((value) => value.project_name);
  };

  const addProjectHandler = async () => {
    setIsLoading(true);
    const inputProjectName = projectInput.trim();
    if (inputProjectName) {
      if (
        !currentProjectNames() ||
        !currentProjectNames().includes(inputProjectName)
      ) {
        await addProject(
          authCtx.organisation,
          inputProjectName,
          authCtx.currentUser.displayName
        );
        setValidationMessage(`Project '${inputProjectName}' created.`);
        setProjectInput("");
      } else {
        setValidationMessage(
          `Project with name '${inputProjectName}' already exists.`
        );
      }
    } else {
      setValidationMessage("Project can't be empty.");
    }
    setTimeout(() => setValidationMessage(""), 2000);
    setIsLoading(false);
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          id="outlined-basic"
          label="New project name"
          variant="outlined"
          onChange={(value) => {
            setProjectInput(value.target.value);
          }}
          value={projectInput}
        />
        <Button onClick={addProjectHandler}>Add project</Button>
        {isLoading && <CircularProgress />}
        <Typography color="secondary.main">{validationMessage}</Typography>
      </Stack>
    </Box>
  );
};

export default NewProjectCard;
