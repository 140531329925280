import React from "react";
import Logo from "../components/signin/Logo.js";
import SignInCard from "../components/signin/SignInCard";
import { Copyright } from "../components/signin/Copyright";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const SignIn = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, #c6487d, #e93842, #ed713f)",
        minHeight: "100vh",
        height: "100%",
        pt: 3,
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
      >
        <Logo />
        <SignInCard />
        <Copyright />
      </Stack>
    </Box>
  );
};

export default SignIn;
