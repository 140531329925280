import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Avatar,
} from "@mui/material";

import ArchiveProject from "./ArchiveProject";

const ProjectCard = (props) => {
  const project_name = (
    <Typography variant="h5" color="primary.main">
      {props.projectName}
    </Typography>
  );
  return (
    <Card sx={{ width: 300 }}>
      <React.Fragment>
        <CardHeader
          avatar={
            <Avatar
              aria-label="project"
              sx={{ backgroundColor: "secondary.main" }}
            >
              {props.projectName.split(" ").length > 1
                ? `${props.projectName.split(" ")[0][0]}${
                    props.projectName.split(" ")[1][0]
                  }`.toUpperCase()
                : props.projectName.split(" ")[0][0].toUpperCase()}
            </Avatar>
          }
          title={project_name}
          subheader={`Created by: ${props.createdBy}`}
          action={
            props.link ? (
              <ArchiveProject
                projectName={props.projectName}
              />
            ) : (
              true
            )
          }
        />
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary" component="p">
            Created on: {props.createdOn.toDate().toLocaleString()}
          </Typography>
        </CardContent>
        {props.link ? (
          <CardActions>
            <Button
              size="small"
              color="secondary"
              component={RouterLink}
              to={`/projects/${props.projectName}/validations`}
            >
              Validations & Feedback
            </Button>
          </CardActions>
        ) : (
          true
        )}
      </React.Fragment>
    </Card>
  );
};

export default ProjectCard;
