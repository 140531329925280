import React, { useContext, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import GoogleIcon from "@mui/icons-material/Google";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
  // GoogleAuthProvider,
  // signInWithPopup,
} from "firebase/auth";
import { config } from "../../secrets/auth-config.js";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context.js";

const app = initializeApp(config);
const auth = getAuth(app);

export default function SignInCard() {
  const emailRef = useRef('')
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signInWithEmailAndPassword(auth, data.get("email"), data.get("password"))
      .then((userCredential) => {
        // Signed in
        const user = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
        };
        authCtx.login(user);
        navigate("/projects");
      })
      .catch((error) => {
        window.alert(error.toString());
      });
  };
  const googleSignIn = async () => {
    window.alert("Sign in with Google is disabled for Early Access.");
    // const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    // const signedIn = await signInWithPopup(auth, provider);
    // if (signedIn) {
    //   authCtx.login(signedIn.user);
    //   navigate("/projects");
    // } else {
    //   alert("Authentication failed.");
    // }
  };
  const handleForgotPassword = () => {
    const email = emailRef.current.value
    sendPasswordResetEmail(auth, email)
    .then(() => {
      window.alert("We have sent you an email to reset your password. Don't forget to check your spam.")
    })
    .catch((error) => {
      const errorMessage = error.message;
      window.alert(errorMessage)
    });
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          p: 2,
          borderRadius: 1,
          boxShadow: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={emailRef}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Stack direction="row">
            <Typography color="grey">
              {"By signing in, you agree to the"}
            </Typography>
            <Link href="https://trubrics.com/terms-of-use/" sx={{ px: 0.5 }}>
              Terms of Use
            </Link>
          </Stack>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Button
            id="signInButton"
            fullWidth
            variant="outlined"
            sx={{ mt: 0, mb: 2 }}
            onClick={() => {
              googleSignIn();
            }}
            startIcon={<GoogleIcon />}
          >
            Sign In With Google
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                onClick={handleForgotPassword}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
