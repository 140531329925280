import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import FeedbackIssuesTable from "./FeedbackIssuesTable.js";

const FeedbackIssues = (props) => {
  const openValue = true;
  const [tabValue, setTabValue] = useState(openValue);
  const tabHandler = (_, newValue) => {
    setTabValue(newValue);
  };

  const numbered_issues = props.issues?.map((element, index) => {
    return { id: `#${index + 1}`, ...element };
  });
  const issues = numbered_issues?.filter((element) => {
    return element.open === tabValue;
  });
  return (
    <Box border={2} borderColor="grey.300" borderRadius={1} pt={1} my={2}>
      <Tabs
        value={tabValue}
        aria-label="open and closed tabs"
        onChange={tabHandler}
        TabIndicatorProps={{
          hidden: true,
        }}
      >
        <Tab label="Open" value={openValue} />
        <Tab label="Closed" value={!openValue} />
      </Tabs>
      <FeedbackIssuesTable issues={issues} />
    </Box>
  );
};

export default FeedbackIssues;
