import { Typography, Link } from "@mui/material";

export function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://trubrics.com/">
        Trubrics
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
