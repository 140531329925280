import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  getFirestore,
  runTransaction,
  query,
  where,
  orderBy,
  arrayUnion,
} from "firebase/firestore";
import { config } from "../secrets/auth-config.js";

const app = initializeApp(config);
export const db = getFirestore(app);

export const getOrganisationFromEmail = async (email) => {
  const orgsRef = collection(db, "organisations");
  const queryRef = query(orgsRef, where("users", "array-contains", email));
  const organisation = await getDocs(queryRef);
  const fetchedOrg = organisation.docs.map((doc) => doc.id);
  if (fetchedOrg.length > 1) {
    console.error(`User belongs to more than one org: ${fetchedOrg}`);
  }
  console.log(`Organisation ${fetchedOrg[0]} loaded.`);
  return fetchedOrg[0];
};

export const addProject = async (organisation, project, createdBy) => {
  const date = new Date();
  await setDoc(
    doc(db, "organisations", organisation, "projects", project),
    {
      createdBy,
      createdOn: date,
      archived: false,
    },
    { merge: true }
  );
  console.log("Project added with ID: ", project);
};

export const addIssueTags = async (organisation, project, feedbackId, tags) => {
  await setDoc(
    doc(
      db,
      "organisations",
      organisation,
      "projects",
      project,
      "feedback",
      feedbackId
    ),
    {
      tags: tags,
    },
    { merge: true }
  );
  console.log("Feedback issue tag added.");
};

export const archiveProject = async (organisation, project) => {
  await setDoc(
    doc(db, "organisations", organisation, "projects", project),
    {
      archived: true,
    },
    { merge: true }
  );
  console.log(`Project ${project} archived.`);
};

export const addDiscussionComment = async (
  organisation,
  project,
  feedback,
  commentData,
  issueStatus
) => {
  // transaction to add a comment and update the discussion status open/closed
  const date = new Date();
  const timestamp = date.getTime();
  commentData = { ...commentData, timestamp };
  const issueDocRef = doc(
    db,
    "organisations",
    organisation,
    "projects",
    project,
    "feedback",
    feedback.toString()
  );
  const commentColRef = collection(issueDocRef, "discussion");
  try {
    await runTransaction(db, async (transaction) => {
      const issueDoc = await transaction.get(issueDocRef);
      if (!issueDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.set(doc(commentColRef), commentData);
      transaction.update(issueDocRef, {
        open: issueStatus,
        collaborators: arrayUnion(commentData.author),
      });
    });
    console.log("Comment added and issue status updated.");
  } catch (e) {
    console.error(e);
  }
};

export const getAllProjects = async (organisation) => {
  const allProjects = await getDocs(
    query(
      collection(db, "organisations", organisation, "projects"),
      orderBy("createdBy")
    )
  );
  const formattedProjects = allProjects.docs
    .filter((doc) => !doc.data().archived)
    .map((doc) => ({
      ...doc.data(),
      project_name: doc.id,
    }));
  console.log(
    `${formattedProjects.length} projects collected for org: ${organisation}.`
  );
  return formattedProjects;
};
