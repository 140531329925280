import LogoS from "../../assets/logo-s.png";
import "./Logo.css";

const Logo = () => {
  return (
    <div className="div-logo">
      <img src={LogoS} alt="logo" className="size-logo" />
    </div>
  );
};

export default Logo;
